import React from "react";
import CompetitiveLandscape from "./CompetitiveLandscape";
import ExitStratergy from "./ExitStratergy";
import FinancialMetrics from "./FinancialMetrics";
import GovernanceAndSupport from "./GovernanceAndSupport";
import MarketOpportunity from "./MarketOpportunity";
import ProductAndDevelopmentStage from "./ProductAndDevelopmentStage";
import Recommendations from "./Recommendations";
import RevenueModelAndGrowth from "./RevenueModelAndGrowth";
import RiskAnalysis from "./RiskAnalysis";
import StrengthAndWeakness from "./StrengthAndWeakness";
import TeamAndExperience from "./TeamAndExperience";
import TractionAndMarketPenetration from "./TractionAndMarketPenetration";


const AssessmentAnalysisContent = ({ parentStateForActiveTab, analysisTabRefs, analysisTabData }) => {

    return (
        <div className="analysisContent">
            <div ref={analysisTabRefs.marketOpportunity}>
                <MarketOpportunity marketOpportunitySectionData={analysisTabData.report["Market Opportunity"]} />
            </div>
            <div ref={analysisTabRefs.competitiveLandscape}>
                <CompetitiveLandscape competitiveLandscapeSectionData={analysisTabData.report["Competitive Landscape"]} />
            </div>
            <div ref={analysisTabRefs.productAndDevelopmentStage}>
                <ProductAndDevelopmentStage productAndDevelopmentStageSectionData={analysisTabData.report["Product & Development Stage"]} />
            </div>
            <div ref={analysisTabRefs.financialMetrics}>
                <FinancialMetrics financialMetricsSectionData={analysisTabData.report["Financial Metrics"]} />
            </div>
            <div ref={analysisTabRefs.tractionAndMarketPenetration}>
                <TractionAndMarketPenetration tractionAndMarketPenetrationSectionData={analysisTabData.report["Traction & Market Penetration"]} />
            </div>
            <div ref={analysisTabRefs.riskAnalysis}>
                <RiskAnalysis riskAnalysisSectionData={analysisTabData.report["Risk Analysis"]} />
            </div>
            <div ref={analysisTabRefs.teamAndExperience}>
                <TeamAndExperience teamAndExperienceSectionData={analysisTabData.report["Team & Experience"]} />
            </div>
            <div ref={analysisTabRefs.governanceAndSupport}>
                <GovernanceAndSupport governanceAndSupportSectionData={analysisTabData.report["Governance & Support"]} />
            </div>
            <div ref={analysisTabRefs.revenueModelAndGrowth}>
                <RevenueModelAndGrowth revenueModelAndGrowthSectionData={analysisTabData.report["Revenue Model & Growth"]} />
            </div>
            <div ref={analysisTabRefs.exitStratergy}>
                <ExitStratergy exitStratergySectionData={analysisTabData.report["Exit Strategy"]} />
            </div>
            {/* <div ref={analysisTabRefs.strengthAndWeakness}>
                <StrengthAndWeakness strengthAndWeaknessSectionData={analysisTabData} />
            </div>
            <div ref={analysisTabRefs.recommendations}>
                <Recommendations recommendationsSectionData={analysisTabData} />
            </div> */}

        </div>
    );
};

export default AssessmentAnalysisContent;