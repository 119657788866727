import React from "react";
import analysisIcon from "../../../assets/linechartAnalysis.png";
import rankingIcon from "../../../assets/medal.png";
import governanceSupport from "../../../assets/governanceSupport.PNG";

const GovernanceAndSupportForInvestmentReadiness = ({ sectionData = {}, analysis = [], rankingData = "", completeReportData = {} }) => {

    const last_item = analysis.length - 1;
    const analysis_text = analysis[last_item] ? analysis[last_item] : "";
    var ranking_class_name = "#4B4B4B";
    if (rankingData === "Below Average") {
        ranking_class_name = "#F64C4C";
    } else if (rankingData === "Average") {
        ranking_class_name = "#FE9B0E";
    } else if (rankingData === "Above Average") {
        ranking_class_name = "#47B881";
    } else {
        ranking_class_name = "#4B4B4B";
    }

    return (
        <div className="investmentReadinessMarketOpportunitySection">
            <div className="investmentReadinessMarketOpportunitySectionHeader">
                <div className="investmentReadinessMarketOpportunitySectionTitleIndex">08</div>
                <div className="investmentReadinessMarketOpportunitySectionTitleName">GOVERNANCE & SUPPORT</div>
            </div>
            <div className="investmentReadinessMarketOpportunitySectionContent">
                <div className="nonGraphicalContentForMarketOpportunity">
                    <div className="analysisContentForMarketOpportunity">
                        <div className="analysisContentTitle">
                            <img src={analysisIcon} alt="Idea" width="15px" height="15px"></img>
                            <div className="analsyisTitleName">Analysis</div>
                        </div>
                        <div className="analysisContentDescription">
                            {analysis_text}
                        </div>
                    </div>
                    <div className="rankingContentForMarketOpportunity">
                        <div className="rankingContentTitle">
                            <img src={rankingIcon} alt="Idea" width="20px" height="20px"></img>
                            <div className="rankingTitleName">Ranking</div>
                        </div>
                        <div className="rankingContentDescription">
                            <div className="keyInformationSectionLine">
                                <div className="keyDifferentiatorsKey">Board of Directors:</div>
                                <div className="keyDifferentiatorsValue" style={{ color: ranking_class_name }}>{rankingData}</div>
                            </div>
                            <div className="keyInformationSectionLine" style={{ marginTop: "5px" }}>
                                <div className="keyDifferentiatorsKey">Investor Relations:</div>
                                <div className="keyDifferentiatorsValue" style={{ color: ranking_class_name }}>{rankingData}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gapSection"></div>
                <div className="graphicalComponentForMarketOpportunity">
                    <div className="analysisContentForMarketOpportunity">
                        <div className="rankingContentTitle">
                            <img src={rankingIcon} alt="Idea" width="20px" height="20px"></img>
                            <div className="rankingTitleName">Key Information</div>
                        </div>
                        <div className="rankingContentDescription">
                            <div className="keyInformationSectionLine">
                                <div className="keyDifferentiatorsKey">Board of Directors:</div>
                                <div className="keyDifferentiatorsValue">{sectionData["Board of Directors"]}</div>
                            </div>
                            <div className="keyInformationSectionLine" style={{ marginTop: "3px" }}>
                                <div className="keyDifferentiatorsKey">Investor Relations:</div>
                                <div className="keyDifferentiatorsValue">{sectionData["Investor Support"]}</div>
                            </div>
                        </div>
                    </div>
                    <div className="someAnalysisImage">
                        <img src={governanceSupport} alt="Idea" width="110px" height="110px"></img>
                    </div>
                </div>
            </div>
            <div className="rankingContentForMarketOpportunity" style={{ marginTop: "3%" }}>
                <div className="rankingContentTitle">
                    <div className="rankingTitleName">Require Fixes</div>
                </div>
                <div className="analysisContentDescription">
                    {sectionData["Needs Fixing"]}, {sectionData["Fix"]}
                </div>
            </div>
            <div className="investmentReadinessSectionDivider"></div>
        </div>
    );
};

export default GovernanceAndSupportForInvestmentReadiness;