import React from "react";
import SectionTitle from "../sectionComponents/SectionTitle";
import SectionContent from "../sectionComponents/SectionContent";

const MarketOpportunity = ({ marketOpportunitySectionData }) => {
    const sample_section_estimates = "The World Agricultural Supply and Demand Estimates (WASDE) is prepared and released by the World Agricultural Outlook Board (WAOB). The report is released monthly, and provides annual forecasts for supply and use of U.S. and world wheat, rice, coarse grains, oilseeds, and cotton. ";
    const section_sample_content = "The market opportunity is substantial, indicating significant potential for growth. A large TAM and SAM suggest that there is a sizable market for the product, which is attractive to investors.";

    const blue_box_data = marketOpportunitySectionData[0] ? marketOpportunitySectionData[0] : "";
    const blue_box_data_list = blue_box_data !== "" ? blue_box_data.split(":") : null;
    const blue_box_title = blue_box_data_list !== null ? blue_box_data_list[0] : "";
    const blue_box_description = blue_box_data_list !== null ? blue_box_data_list[1] : "";

    const green_box_data = marketOpportunitySectionData[1] ? marketOpportunitySectionData[1] : "";
    const green_box_data_list = green_box_data !== "" ? green_box_data.split(":") : null;
    const green_box_title = green_box_data_list !== null ? green_box_data_list[0] : "";
    const green_box_description = green_box_data_list !== null ? green_box_data_list[1] : "";

    const analysisSectionTextDescription = marketOpportunitySectionData[2] ? marketOpportunitySectionData[2] : "";

    return (
        <div className="sectionContainer">
            <SectionTitle sectionTitle="Market Opportunity" />
            <SectionContent
                sectionEstimates={sample_section_estimates}
                sectionContent={section_sample_content}
                blue_box_title={blue_box_title}
                blue_box_description={blue_box_description}
                green_box_title={green_box_title}
                green_box_description={green_box_description}
                analysisSectionTextDescription={analysisSectionTextDescription}
                comingFrom="Market Opportunity"
            />
        </div>
    );
};

export default MarketOpportunity;