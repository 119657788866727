import React, { useState } from "react";

const AssessmentReportSectionNavigationComponent = ({ parentStateForActiveTab, analysisTabRefs }) => {
    const [activeSection, setActiveSection] = useState("Market Opportunity");

    const handleClickAssessmentReportAnalysisNavigationItem = (sectionName, ref) => {
        setActiveSection(sectionName);  // Set the active section
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="listOfAssessmentReportSections">
            <div
                className={(activeSection === "Market Opportunity") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Market Opportunity", analysisTabRefs.marketOpportunity)}
            >
                Market Opportunity
            </div>
            <div
                className={(activeSection === "Competitive Landscape") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Competitive Landscape", analysisTabRefs.competitiveLandscape)}
            >
                Competitive Landscape
            </div>
            <div
                className={(activeSection === "Product and Development Stage") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Product and Development Stage", analysisTabRefs.productAndDevelopmentStage)}
            >
                Product and Development Stage
            </div>
            <div
                className={(activeSection === "Financial Metrics") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Financial Metrics", analysisTabRefs.financialMetrics)}
            >
                Financial Metrics
            </div>
            <div
                className={(activeSection === "Traction and Market Penetration") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Traction and Market Penetration", analysisTabRefs.tractionAndMarketPenetration)}
            >
                Traction and Market Penetration
            </div>
            <div
                className={(activeSection === "Risk Analysis") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Risk Analysis", analysisTabRefs.riskAnalysis)}
            >
                Risk Analysis
            </div>
            <div
                className={(activeSection === "Team and Experience") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Team and Experience", analysisTabRefs.teamAndExperience)}
            >
                Team and Experience
            </div>
            <div
                className={(activeSection === "Governance and Support") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Governance and Support", analysisTabRefs.governanceAndSupport)}
            >
                Governance and Support
            </div>
            <div
                className={(activeSection === "Revenue Model and Growth") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Revenue Model and Growth", analysisTabRefs.revenueModelAndGrowth)}
            >
                Revenue Model and Growth
            </div>
            <div
                className={(activeSection === "Exit Strategy") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Exit Strategy", analysisTabRefs.exitStratergy)}
            >
                Exit Strategy
            </div>
            {/* <div
                className={(activeSection === "Strengths & Weaknesses") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Strengths & Weaknesses", analysisTabRefs.strengthAndWeakness)}
            >
                Strengths & Weaknesses
            </div>
            <div
                className={(activeSection === "Recommendations") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Recommendations", analysisTabRefs.recommendations)}
            >
                Recommendations
            </div> */}


        </div>
    );
};

export default AssessmentReportSectionNavigationComponent;