import React from "react";
import { Button } from "antd";

const GenerateADocumentButton = () => {
    return (
        <Button className="generateADocumentButton" style={{ width: "75%" }}>
            + Generate a Document
        </Button>
    );
};

export default GenerateADocumentButton;