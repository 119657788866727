import React from "react";
import SectionContent from "../sectionComponents/SectionContent";
import SectionTitle from "../sectionComponents/SectionTitle";

const TeamAndExperience = ({ teamAndExperienceSectionData }) => {
    const sample_section_estimates = "The World Agricultural Supply and Demand Estimates (WASDE) is prepared and released by the World Agricultural Outlook Board (WAOB). The report is released monthly, and provides annual forecasts for supply and use of U.S. and world wheat, rice, coarse grains, oilseeds, and cotton. ";
    const section_sample_content = "Lebanon’s health ministry says 492 killed, including 35 children and 58 women. More than 1,600 wounded. Israel says 1,300 Hezbollah assets struck. The report also covers U.S. supply and use of sugar, meat, poultry eggs and milk, as well as Mexico’s supply and use of sugar. The WAOB chairs the Interagency Commodity Estimates Committees (ICECs), which include analysts from key USDA agencies who compile and interpret information from USDA and other domestic and foreign sources to produce the report. For more information about the WASDE process and data, visit the WASDE FAQs page.";

    const yellow_box_data = teamAndExperienceSectionData[0] ? teamAndExperienceSectionData[0] : "";
    const yellow_box_data_list = yellow_box_data !== "" ? yellow_box_data.split(":") : null;
    const yellow_box_title = yellow_box_data_list !== null ? yellow_box_data_list[0] : "";
    const yellow_box_description = yellow_box_data_list !== null ? yellow_box_data_list[1] : "";

    const blue_box_data = teamAndExperienceSectionData[1] ? teamAndExperienceSectionData[1] : "";
    const blue_box_data_list = blue_box_data !== "" ? blue_box_data.split(":") : null;
    const blue_box_title = blue_box_data_list !== null ? blue_box_data_list[0] : "";
    const blue_box_description = blue_box_data_list !== null ? blue_box_data_list[1] : "";

    const analysisSectionTextDescription = teamAndExperienceSectionData[2] ? teamAndExperienceSectionData[2] : "";

    return (
        <div className="sectionContainer">
            <SectionTitle sectionTitle="Team And Experience" />
            <SectionContent
                sectionEstimates={sample_section_estimates}
                sectionContent={section_sample_content}
                blue_box_title={blue_box_title}
                blue_box_description={blue_box_description}
                yellow_box_title={yellow_box_title}
                yellow_box_description={yellow_box_description}
                analysisSectionTextDescription={analysisSectionTextDescription}
                comingFrom="Team And Experience"
            />
        </div>
    );
};

export default TeamAndExperience;