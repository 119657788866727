import React, { useState } from "react";

const ShortAnswerTextComponent = ({ initialState = "", onValueChange = {} }) => {
    // Use local state to manage the text input value
    const [inputValue, setInputValue] = useState(initialState);

    // Handle input change and notify parent component
    const handleChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onValueChange(newValue); // Call the onValueChange prop function
    };

    const inputStyles = {
        width: "65%",
        maxWidth: "65%",
        padding: "10px",
        fontSize: "16px",
        border: "none",
        borderBottom: "1px solid #ccc",
        boxSizing: "border-box"
    };

    return (
        <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            placeholder="Enter your answer"
            style={inputStyles}
        />
    );
};

export default ShortAnswerTextComponent;