import React from "react";
import SectionContent from "../sectionComponents/SectionContent";
import SectionTitle from "../sectionComponents/SectionTitle";

const GovernanceAndSupport = ({governanceAndSupportSectionData}) => {
    const sample_section_estimates = "The World Agricultural Supply and Demand Estimates (WASDE) is prepared and released by the World Agricultural Outlook Board (WAOB). The report is released monthly, and provides annual forecasts for supply and use of U.S. and world wheat, rice, coarse grains, oilseeds, and cotton. ";
    const section_sample_content = "Lebanon’s health ministry says 492 killed, including 35 children and 58 women. More than 1,600 wounded. Israel says 1,300 Hezbollah assets struck. The report also covers U.S. supply and use of sugar, meat, poultry eggs and milk, as well as Mexico’s supply and use of sugar. The WAOB chairs the Interagency Commodity Estimates Committees (ICECs), which include analysts from key USDA agencies who compile and interpret information from USDA and other domestic and foreign sources to produce the report. For more information about the WASDE process and data, visit the WASDE FAQs page.";
    
    const blue_box_data = governanceAndSupportSectionData[0] ? governanceAndSupportSectionData[0] : "";
    const blue_box_data_list = blue_box_data !== "" ? blue_box_data.split(":") : null;
    const blue_box_title = blue_box_data_list !== null ? blue_box_data_list[0] : "";
    const blue_box_description = blue_box_data_list !== null ? blue_box_data_list[1] : "";

    const green_box_data = governanceAndSupportSectionData[1] ? governanceAndSupportSectionData[1] : "";
    const green_box_data_list = green_box_data !== "" ? green_box_data.split(":") : null;
    const green_box_title = green_box_data_list !== null ? green_box_data_list[0] : "";
    const green_box_description = green_box_data_list !== null ? green_box_data_list[1] : "";

    const analysisSectionTextDescription = governanceAndSupportSectionData[2] ? governanceAndSupportSectionData[2] : "";
    
    return (
        <div className="sectionContainer">
            <SectionTitle sectionTitle="Governance And Support" />
            <SectionContent
                sectionEstimates={sample_section_estimates}
                sectionContent={section_sample_content}
                blue_box_title={blue_box_title}
                blue_box_description={blue_box_description}
                green_box_title={green_box_title}
                green_box_description={green_box_description}
                analysisSectionTextDescription={analysisSectionTextDescription}
                comingFrom="Governance And Support"
            />
        </div>
    );
};

export default GovernanceAndSupport;