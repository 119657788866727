import React, { useState, useEffect } from "react";

const AssessmentReportSectionNavigationComponentForInvestmentReadiness = ({ parentStateForActiveTab, analysisTabRefs }) => {
    const sectionRefs = [
        { name: 'Company Overview', ref: analysisTabRefs.companyOverview },
        { name: 'Market Opportunity', ref: analysisTabRefs.marketOpportunity },
        { name: 'Competitive Landscape', ref: analysisTabRefs.competitiveLandscape },
        { name: 'Product & Development Stage', ref: analysisTabRefs.productAndDevelopmentStage },
        { name: 'Financial Metrics', ref: analysisTabRefs.financialMetrics },
        { name: 'Traction and Market Penetration', ref: analysisTabRefs.tractionAndMarketPenetration },
        { name: 'Risk Analysis', ref: analysisTabRefs.riskAnalysis },
        { name: 'Team & Experience', ref: analysisTabRefs.teamAndExperience },
        { name: 'Governance & Support', ref: analysisTabRefs.governanceAndSupport },
        { name: 'Revenue Model and Growth', ef: analysisTabRefs.revenueModelAndGrowth },
        { name: 'Exit Strategy', ref: analysisTabRefs.operationEfficiency },
        { name: 'Recommendations & Improvement', ref: analysisTabRefs.recommendations },
        { name: 'Conclusion', ref: analysisTabRefs.investmentReadinessConclusion },
    ];

    const [activeSection, setActiveSection] = useState("Company Overview");

    const handleClickAssessmentReportAnalysisNavigationItem = (sectionName, ref) => {
        setActiveSection(sectionName);  // Set the active section
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="listOfAssessmentReportSections">

            <div
                className={(activeSection === "Company Overview") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Company Overview", analysisTabRefs.companyOverview)}
            >
                Company Overview
            </div>

            <div
                className={(activeSection === "Market Opportunity") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Market Opportunity", analysisTabRefs.marketOpportunity)}
            >
                Market Opportunity
            </div>
            <div
                className={(activeSection === "Competitive Landscape") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Competitive Landscape", analysisTabRefs.competitiveLandscape)}
            >
                Competitive Landscape
            </div>
            <div
                className={(activeSection === "Product and Development Stage") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Product and Development Stage", analysisTabRefs.productAndDevelopmentStage)}
            >
                Product & Development Stage
            </div>
            <div
                className={(activeSection === "Financial Metrics") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Financial Metrics", analysisTabRefs.financialMetrics)}
            >
                Financial Metrics
            </div>
            <div
                className={(activeSection === "Traction and Market Penetration") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Traction and Market Penetration", analysisTabRefs.tractionAndMarketPenetration)}
            >
                Traction and Market Penetration
            </div>
            <div
                className={(activeSection === "Risk Analysis") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Risk Analysis", analysisTabRefs.riskAnalysis)}
            >
                Risk Analysis
            </div>
            <div
                className={(activeSection === "Team & Experience") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Team & Experience", analysisTabRefs.teamAndExperience)}
            >
                Team & Experience
            </div>
            <div
                className={(activeSection === "Governance & Support") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Governance & Support", analysisTabRefs.governanceAndSupport)}
            >
                Governance & Support
            </div>
            <div
                className={(activeSection === "Revenue Model and Growth") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Revenue Model and Growth", analysisTabRefs.revenueModelAndGrowth)}
            >
                Revenue Model and Growth
            </div>
            <div
                className={(activeSection === "Exit Strategy") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Exit Strategy", analysisTabRefs.operationEfficiency)}
            >
                Exit Strategy
            </div>

            {/* <div
                className={(activeSection === "Recommendations & Improvement") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Recommendations & Improvement", analysisTabRefs.recommendations)}
            >
                Recommendations & Improvement
            </div>

            <div
                className={(activeSection === "Conclusion") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Conclusion", analysisTabRefs.investmentReadinessConclusion)}
            >
                Conclusion
            </div> */}
        </div>
    );
};

export default AssessmentReportSectionNavigationComponentForInvestmentReadiness;