import React from "react";
import CompanyOverviewForInvestmentReadiness from "./CompanyOverviewForInvestmentReadiness";
import MarketOpportunityComponentForInvestmentReadiness from "./MarketOpportunityComponentForInvestmentReadiness";
import CompetitiveLandscapeForInvestmentReadiness from "./CompetitiveLandscapeForInvestmentReadiness";
import ProductAndDevelopmentStageForInvestmentReadiness from "./ProductAndDevelopmentStageForInvestmentReadiness";
import FInancialMetricsForInvestmentReadiness from "./FInancialMetricsForInvestmentReadiness";
import TractionAndMarketPenetrationForInvestmentReadiness from "./TractionAndMarketPenetrationForInvestmentReadiness";
import RiskAnalysisForInvestmentReadiness from "./RiskAnalysisForInvestmentReadiness";
import TeamExperienceForInvestmentReadiness from "./TeamExperienceForInvestmentReadiness";
import GovernanceAndSupportForInvestmentReadiness from "./GovernanceAndSupportForInvestmentReadiness";
import RevenueModelAndGrowthForInvestmentReadiness from "./RevenueModelAndGrowthForInvestmentReadiness";
import OperationalEfficiencyForInvestmentReadiness from "./OperationalEfficiencyForInvestmentReadiness";
import RecommendationAndImprovementsForInvestmentReadiness from "./RecommendationAndImprovementsForInvestmentReadiness";
import ConclusionForInvestmentReadiness from "./ConclusionForInvestmentReadiness";
import InvestmentOpportunityForInvestmentReadiness from "./InvestmentOpportunityForInvestmentReadiness";


import "../../../css/AssessmentInvestmentReadinessContent.css";

const AssessmentInvestmentReadinessContent = ({
    parentStateForActiveTab,
    analysisTabRefs,
    analysisTabData,
    executableSolutionsTabData,
    benchmarksTabData,
    investmentReadinessTabData,
    completeReportData
}) => {

    return (
        <div className="investmentReadinessContent">
            <div ref={analysisTabRefs.companyOverview}>
                <CompanyOverviewForInvestmentReadiness completeReportData={completeReportData} />
            </div>

            <div ref={analysisTabRefs.marketOpportunity}>
                <MarketOpportunityComponentForInvestmentReadiness
                    sectionData={investmentReadinessTabData.report["Market Opportunity"]}
                    analysis={analysisTabData.report["Market Opportunity"]}
                    rankingData={benchmarksTabData.report["Market Opportunity"]["Ranking"]}
                />
            </div>

            <div ref={analysisTabRefs.competitiveLandscape}>
                <CompetitiveLandscapeForInvestmentReadiness
                    sectionData={investmentReadinessTabData.report["Competitive Landscape"]}
                    analysis={analysisTabData.report["Competitive Landscape"]}
                    rankingData={benchmarksTabData.report["Competitive Landscape"]["Ranking"]}
                    completeReportData={completeReportData}
                />
            </div>

            <div ref={analysisTabRefs.productAndDevelopmentStage}>
                <ProductAndDevelopmentStageForInvestmentReadiness
                    sectionData={investmentReadinessTabData?.report?.["Product & Development Stage"] ?? {}}
                    analysis={analysisTabData?.report?.["Product & Development Stage"] ?? []}
                    rankingData={benchmarksTabData?.report?.["Product & Development Stage"]?.["Ranking"] ?? "No Ranking Available"}
                    completeReportData={completeReportData ?? {}}
                />
            </div>

            <div ref={analysisTabRefs.financialMetrics}>
                <FInancialMetricsForInvestmentReadiness
                    sectionData={investmentReadinessTabData?.report?.["Financial Metrics"] ?? {}}
                    analysis={analysisTabData?.report?.["Financial Metrics"] ?? []}
                    rankingData={benchmarksTabData?.report?.["Financial Metrics"]?.["Ranking"] ?? "No Ranking Available"}
                    completeReportData={completeReportData ?? {}}
                />
            </div>

            <div ref={analysisTabRefs.tractionAndMarketPenetration}>
                <TractionAndMarketPenetrationForInvestmentReadiness
                    sectionData={investmentReadinessTabData?.report?.["Traction & Market Penetration"] ?? {}}
                    analysis={analysisTabData?.report?.["Traction & Market Penetration"] ?? []}
                    rankingData={benchmarksTabData?.report?.["Traction & Market Penetration"]?.["Ranking"] ?? "No Ranking Available"}
                    completeReportData={completeReportData ?? {}}
                />
            </div>

            <div ref={analysisTabRefs.riskAnalysis}>
                <RiskAnalysisForInvestmentReadiness
                    sectionData={investmentReadinessTabData?.report?.["Risk Analysis"] ?? {}}
                    analysis={analysisTabData?.report?.["Risk Analysis"] ?? []}
                    rankingData={benchmarksTabData?.report?.["Risk Analysis"]?.["Ranking"] ?? "No Ranking Available"}
                    completeReportData={completeReportData ?? {}}
                />
            </div>

            <div ref={analysisTabRefs.teamAndExperience}>
                <TeamExperienceForInvestmentReadiness
                    sectionData={investmentReadinessTabData?.report?.["Team & Experience"] ?? {}}
                    analysis={analysisTabData?.report?.["Team & Experience"] ?? []}
                    rankingData={benchmarksTabData?.report?.["Team & Experience"]?.["Ranking"] ?? "No Ranking Available"}
                    completeReportData={completeReportData ?? {}}
                />
            </div>

            <div ref={analysisTabRefs.governanceAndSupport}>
                <GovernanceAndSupportForInvestmentReadiness
                    sectionData={investmentReadinessTabData?.report?.["Governance & Support"] ?? {}}
                    analysis={analysisTabData?.report?.["Governance & Support"] ?? []}
                    rankingData={benchmarksTabData?.report?.["Governance & Support"]?.["Ranking"] ?? "No Ranking Available"}
                    completeReportData={completeReportData ?? {}}
                />
            </div>

            <div ref={analysisTabRefs.revenueModelAndGrowth}>
                <RevenueModelAndGrowthForInvestmentReadiness
                    sectionData={investmentReadinessTabData?.report?.["Revenue Model & Growth"] ?? {}}
                    analysis={analysisTabData?.report?.["Revenue Model & Growth"] ?? []}
                    rankingData={benchmarksTabData?.report?.["Revenue Model & Growth"]?.["Ranking"] ?? "No Ranking Available"}
                    completeReportData={completeReportData ?? {}}
                />
            </div>

            <div ref={analysisTabRefs.operationEfficiency}>
                <OperationalEfficiencyForInvestmentReadiness
                    sectionData={investmentReadinessTabData?.report?.["Exit Strategy"] ?? {}}
                    analysis={analysisTabData?.report?.["Exit Strategy"] ?? []}
                    rankingData={benchmarksTabData?.report?.["Exit Strategy"]?.["Ranking"] ?? "No Ranking Available"}
                    completeReportData={completeReportData ?? {}}
                />
            </div>

            {/* <div ref={analysisTabRefs.recommendations}>
                <RecommendationAndImprovementsForInvestmentReadiness />
            </div>

            <div ref={analysisTabRefs.investmentReadinessConclusion}>
                <ConclusionForInvestmentReadiness />
            </div>

            <div ref={analysisTabRefs.investmentOpportunity}>
                <InvestmentOpportunityForInvestmentReadiness />
            </div> */}

        </div>
    );
};

export default AssessmentInvestmentReadinessContent;