import React from "react";
import analysisIcon from "../../../assets/linechartAnalysis.png";
import rankingIcon from "../../../assets/medal.png";

const MarketOpportunityComponentForInvestmentReadiness = ({
    sectionData = {},
    analysis = [],
    rankingData = "",
}) => {
    const last_item = analysis.length - 1;
    const analysis_text = analysis[last_item] ? analysis[last_item] : "";
    var ranking_class_name = "#4B4B4B";
    if (rankingData === "Below Average") {
        ranking_class_name = "#F64C4C";
    } else if (rankingData === "Average") {
        ranking_class_name = "#FE9B0E";
    } else if (rankingData === "Above Average") {
        ranking_class_name = "#47B881";
    } else {
        ranking_class_name = "#4B4B4B";
    }
    return (
        <div className="investmentReadinessMarketOpportunitySection">
            <div className="investmentReadinessMarketOpportunitySectionHeader">
                <div className="investmentReadinessMarketOpportunitySectionTitleIndex">
                    01
                </div>
                <div className="investmentReadinessMarketOpportunitySectionTitleName">
                    MARKET OPPORTUNITY
                </div>
            </div>
            <div className="investmentReadinessMarketOpportunitySectionContent">
                <div className="nonGraphicalContentForMarketOpportunity">
                    <div className="analysisContentForMarketOpportunity">
                        <div className="analysisContentTitle">
                            <img
                                src={analysisIcon}
                                alt="Idea"
                                width="15px"
                                height="15px"
                            ></img>
                            <div className="analsyisTitleName">Analysis</div>
                        </div>
                        <div className="analysisContentDescription">{analysis_text}</div>
                    </div>
                    <div className="rankingContentForMarketOpportunity">
                        <div className="rankingContentTitle">
                            <img
                                src={rankingIcon}
                                alt="Idea"
                                width="20px"
                                height="20px"
                            ></img>
                            <div className="rankingTitleName">Ranking</div>
                        </div>
                        <div className="rankingContentDescription">
                            <div className="tamContentSectionLine">
                                <div className="tamKey">TAM:</div>
                                <div
                                    className="tamKeyValue"
                                    style={{ color: ranking_class_name }}
                                >
                                    {rankingData}
                                </div>
                            </div>
                            <div className="samContentSectionLine">
                                <div className="samKey">SAM:</div>
                                <div
                                    className="samKeyValue"
                                    style={{ color: ranking_class_name }}
                                >
                                    {rankingData}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gapSection"></div>
                <div className="graphicalComponentForMarketOpportunity">
                    <div class="outer-circle">
                        <div class="outerText">
                            <div>Total Addressable Market</div>
                            <div className="subOuterTextPartTwo">{sectionData["TAM"]}</div>
                        </div>
                        <div class="inner-circle">
                            <div className="innerText">
                                <div>Serviceable Addressable Market</div>
                                <div className="subInnerTextPartTwo">{sectionData["SAM"]}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rankingContentForMarketOpportunity" style={{ marginTop: "3%" }}>
                <div className="rankingContentTitle">
                    <div className="rankingTitleName">Require Fixes</div>
                </div>
                <div className="analysisContentDescription">
                    {sectionData["Needs Fixing"]}, {sectionData["Fix"]}
                </div>
            </div>
            <div className="investmentReadinessSectionDivider"></div>
        </div>
    );
};

export default MarketOpportunityComponentForInvestmentReadiness;
