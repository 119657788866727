import React from "react";
import GenerateADocumentButton from "./actionContainer/GenerateADocumentButton";
import AssessmentReportSectionNavigationComponent from "./actionContainer/AssessmentReportSectionNavigationComponent";
import AssessmentReportSectionNavigationComponentForInvestmentReadiness from "./actionContainer/AssessmentReportSectionNavigationComponentForInvestmentReadiness";
import AssessmentReportSectionNavigationComponentForExecutableSolutions from "./actionContainer/AssessmentReportSectionNavigationComponentForExecutableSolutions";
import AssessmentReportSectionNavigationComponentForBenchmark from "./actionContainer/AssessmentReportSectionNavigationComponentForBenchmark";

import "../../css/AssessmentReportActionContainer.css";

const AssessmentReportActionContainer = ({ parentStateForActiveTab, analysisTabRefs }) => {
    return (
        <div className="actionsContainer">
            <GenerateADocumentButton />
            {parentStateForActiveTab === "Analysis" && (
                <AssessmentReportSectionNavigationComponent parentStateForActiveTab={parentStateForActiveTab} analysisTabRefs={analysisTabRefs} />
            )}
            {parentStateForActiveTab === "Investment Readiness" && (
                <AssessmentReportSectionNavigationComponentForInvestmentReadiness parentStateForActiveTab={parentStateForActiveTab} analysisTabRefs={analysisTabRefs} />
            )}
            {parentStateForActiveTab === "Executable solutions" && (
                <AssessmentReportSectionNavigationComponentForExecutableSolutions parentStateForActiveTab={parentStateForActiveTab} analysisTabRefs={analysisTabRefs} />
            )}

            {parentStateForActiveTab === "Benchmarks" && (
                <AssessmentReportSectionNavigationComponentForBenchmark parentStateForActiveTab={parentStateForActiveTab} analysisTabRefs={analysisTabRefs} />
            )}


        </div>
    );
};

export default AssessmentReportActionContainer;